.thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    padding: 10px;
  }
  
  .thumbnail {
    flex: 1 0 21%; /* Adjusted to fit 4 items per row with some spacing */
    box-sizing: border-box;
    margin: 5px;
  }
  
  .thumbnail img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  