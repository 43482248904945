.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add the following styles to App.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #786;
  padding: 10px 20px;
  color: rgb(31, 188, 227);
  padding: 10px;
  height: 50px; /* Adjust height as needed */
}

.footer {
  background-color: #786;
  color: white;
  padding: 10px;
  height: 50px; /* Adjust height as needed */
  text-align: center;
}

.left-section {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.right-section {
  display: flex;
}

.tagline {
  max-width: 370px;
  margin-left: 10px;
  margin-right: auto;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 30px;
}

.nav-item {
  margin-left: 20px;
  font-size: 18px;
  text-decoration: none;
  color: white;
}

/* App.css */
body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.body {
  flex: 1; /* Take remaining space */
  padding: 20px;
  overflow-y: auto; /* Allow scrolling if content exceeds height */
}

.surah {
  flex: 1; /* Take remaining space */
  padding: 80px;
  border: 20px;
  overflow-y: auto; /* Allow scrolling if content exceeds height */
}

.glyph-word {
  font-family: p1-v1;
  font-size: .4em; /* Adjust this to your needs */
}

.error-message {
  font-weight: bold;
  color: red; /* Optional: add color to highlight the error */
}
