.multi-line-button {
    background-color: #786;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px; /* Adjust width as needed */
  }
  
  .multi-line-button span {
    display: block;
    text-align: center; /* Center align the text */
  }
  